<template>
  <span>
    <template v-if="iconName === 'Content'">
      <ContentIcon />
    </template>
    <template v-if="iconName === 'News'">
      <NewsIcon />
    </template>
    <template v-if="iconName === 'Calendar'">
      <CalendarIcon />
    </template>
    <template v-if="iconName === 'Location'">
      <LocationIcon />
    </template>
    <template v-if="iconName === 'Integration'">
      <IntegrationIcon />
    </template>
    <template v-if="iconName === 'Engagement'">
      <EngagementIcon />
    </template>
    <template v-if="iconName === 'MyWaste'">
      <MyWasteIcon />
    </template>
    <template v-if="iconName === 'Recreation'">
      <RecreationIcon />
    </template>
    <template v-if="iconName === 'Form builder'">
      <FormBuilderIcon />
    </template>
  </span>
</template>
<script>
import CalendarIcon from '../../../assets/svg/calendar.svg'
import ContentIcon from '../../../assets/svg/content.svg'
import EngagementIcon from '../../../assets/svg/engagement.svg'
import IntegrationIcon from '../../../assets/svg/integration.svg'
import LocationIcon from '../../../assets/svg/location.svg'
import MyWasteIcon from '../../../assets/svg/mywaste.svg'
import NewsIcon from '../../../assets/svg/news.svg'
import RecreationIcon from '../../../assets/svg/recreation.svg'
import FormBuilderIcon from '../../../assets/svg/form-builder.svg'

export default {
  name: 'WidgetIcons',
  props: ['iconName'],
  data () {
    return {
      widgetDetailsLoading: false,
      saveLoadingIcon: false
    }
  },
  components: {
    CalendarIcon,
    ContentIcon,
    EngagementIcon,
    IntegrationIcon,
    LocationIcon,
    MyWasteIcon,
    NewsIcon,
    RecreationIcon,
    FormBuilderIcon
  }
}
</script>
