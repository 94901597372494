<template>
  <section class="py-4 px-5">
    <router-link to="/widgets" class="go-back-link"
      ><BIconArrowLeftShort />Back to widgets</router-link
    >
    <h3 class="page-header pt-3">Add Widget</h3>
    <p class="pt-3">
      Widgets are a central feature of Citizen Portal. This is where you can
      provide a Secure, Responsive, Accessible and Personalized experience to
      your citizens and bring all the information they care about directly to
      them.
    </p>
    <div v-if="availableWidgetsCount">
      <h4 class="page-sub-header py-1"><strong>Available Widgets</strong></h4>
      <b-row>
        <b-col
          v-for="(widget, index) in getWidgets(true)"
          :key="index"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="3"
          class="pb-4 mb-1"
        >
          <b-card
            tag="article"
            class="clickable-card"
            @click="navigate(widget)"
          >
            <b-row class="no-gutters widgetTitleRow">
              <span
                class="widget-icon"
                :style="{
                  'background-color': widget.iconColor,
                  'border-radius': '5px 0px',
                }"
              >
                <WidgetIcons :iconName="widget.widgetCategoryName" />
              </span>

              <b-col>
                <p class="widgetTitle">
                  <strong>{{ widget.widgetCategoryName }}</strong>
                </p>
                <p class="widgetCount">{{ remainingLabelText(widget) }}</p>
              </b-col>
            </b-row>
            <b-card-text>{{ widget.description }} </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div v-if="otherWidgetsCount">
      <h4 class="page-sub-header pt-5"><strong>Other Widgets</strong></h4>
      <p>
        <a
          :href="contactUrl"
          target="_blank"
          >Contact us</a
        >
        if you would like to purchase the following widgets
      </p>
      <b-row>
        <b-col
          v-for="(widget, index) in getWidgets(false)"
          :key="index"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="3"
          class="pb-4 mb-1"
        >
          <b-card tag="article">
            <b-row class="no-gutters widgetTitleRow">
              <span
                class="widget-icon"
                :style="{
                  'background-color': widget.iconColor,
                  'border-radius': '5px 0px',
                }"
              >
                <WidgetIcons :iconName="widget.widgetCategoryName" />
              </span>
              <p class="otherWidgetTitle">
                <strong>{{ widget.widgetCategoryName }}</strong>
              </p>
            </b-row>
            <b-card-text>{{ widget.description }} </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import { WidgetDescriptions, CONTACT_URL } from '../../../utilities/constants'
import WidgetIcons from './WidgetIcons.vue'
import { BIconArrowLeftShort } from 'bootstrap-vue'

export default {
  name: 'AddWidget',
  data () {
    return {
      widgetDetailsLoading: false,
      saveLoadingIcon: false,
      contactUrl: CONTACT_URL,
    }
  },
  components: {
    BIconArrowLeftShort,
    WidgetIcons
  },
  computed: {
    availableWidgetsCount () {
      return this.getWidgets(true).length > 0
    },
    otherWidgetsCount () {
      return this.getWidgets(false).length > 0
    },
    restrictAddWidgetToMultipleCity () {
      return (
        this.selectedTenant.length > 1 ||
        this.selectedTenant.some((tenant) => tenant.tenantID === 0)
      )
    },
    ...mapState({
      widgets: (state) => state.widgets.widgets,
      selectedTenant: (state) => state.common.selectedTenant,
      userRole: (state) => state.common.userRole,
      applicableWidgets: (state) => state.widgets.applicableWidgets
    })
  },
  mounted () {
    this.getApplicableWidgets()
  },
  methods: {
    navigate (widget) {
      if (
        widget.widgetCategoryId === 4 &&
        !this.selectedTenant[0].isAddressSetupDone
      ) {
        this.$store.commit('common/setCustomToastData', {
          message: false,
          key: 'ADDRESS_SETUP_NOT_DONE_ERROR',
          type: 'danger'
        })
      } else {
        if (widget.existingCnt < widget.allowedCnt) {
          this.$router
            .replace(`/widgets/add-widget/${widget.widgetCategoryId}`)
            .catch(() => {})
        } else {
          this.$store.commit('common/setCustomToastData', {
            message: false,
            key: 'MAX_WIDGET_TYPE_ERROR',
            type: 'danger'
          })
        }
      }
    },
    getWidgets (isSelected) {
      const applicableWidgets = []

      for (const widget of this.applicableWidgets) {
        const widgetDescription = WidgetDescriptions.find(
          (elem) => widget.widgetCategoryName === elem.widgetCategoryName
        )
        applicableWidgets.push({
          ...widget,
          ...{
            description: widgetDescription ? widgetDescription.description : '',
            iconColor: widgetDescription ? widgetDescription.color : ''
          }
        })
      }

      return applicableWidgets.filter(
        (widget) => widget.isSelected === isSelected
      )
    },
    getApplicableWidgets () {
      if (this.selectedTenant.length && !this.restrictAddWidgetToMultipleCity) {
        this.$store.dispatch('widgets/getApplicableWidgets')
      } else {
        this.$store.commit('widgets/setApplicableWidgets', [])
      }
    },
    remainingLabelText (widget) {
      const remaining = widget.allowedCnt - widget.existingCnt
      return `${remaining} of
                    ${widget.allowedCnt} remaining`
    }
  },
  watch: {
    selectedTenant (newValue, oldValue) {
      this.getApplicableWidgets()
    },
    widgetDetails: {
      deep: true,
      handler () {
        this.validateWidgetForm()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.arrow-right-icon {
  position: absolute;
  right: 25px;
}
.clickable-card:hover {
  cursor: pointer;
  box-shadow: 1px 1px 10px 0.1px #888888;
}
.widget-icon {
  width: 46px;
  height: 43px;
  text-align: center;
  padding-top: 5px;
  margin-right: 15px;
}
.widgetTitle {
  align-self: flex-end;
  margin-bottom: 0px;
}
.otherWidgetTitle {
  align-self: flex-end;
}
.widgetCount {
  font-size: 14px;
  color: #717171;
  margin-bottom: 5px;
}
.card {
  height: 100%;
}
</style>
