var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"py-4 px-5"},[_c('router-link',{staticClass:"go-back-link",attrs:{"to":"/widgets"}},[_c('BIconArrowLeftShort'),_vm._v("Back to widgets")],1),_c('h3',{staticClass:"page-header pt-3"},[_vm._v("Add Widget")]),_c('p',{staticClass:"pt-3"},[_vm._v(" Widgets are a central feature of Citizen Portal. This is where you can provide a Secure, Responsive, Accessible and Personalized experience to your citizens and bring all the information they care about directly to them. ")]),(_vm.availableWidgetsCount)?_c('div',[_vm._m(0),_c('b-row',_vm._l((_vm.getWidgets(true)),function(widget,index){return _c('b-col',{key:index,staticClass:"pb-4 mb-1",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('b-card',{staticClass:"clickable-card",attrs:{"tag":"article"},on:{"click":function($event){return _vm.navigate(widget)}}},[_c('b-row',{staticClass:"no-gutters widgetTitleRow"},[_c('span',{staticClass:"widget-icon",style:({
                'background-color': widget.iconColor,
                'border-radius': '5px 0px',
              })},[_c('WidgetIcons',{attrs:{"iconName":widget.widgetCategoryName}})],1),_c('b-col',[_c('p',{staticClass:"widgetTitle"},[_c('strong',[_vm._v(_vm._s(widget.widgetCategoryName))])]),_c('p',{staticClass:"widgetCount"},[_vm._v(_vm._s(_vm.remainingLabelText(widget)))])])],1),_c('b-card-text',[_vm._v(_vm._s(widget.description)+" ")])],1)],1)}),1)],1):_vm._e(),(_vm.otherWidgetsCount)?_c('div',[_vm._m(1),_c('p',[_c('a',{attrs:{"href":_vm.contactUrl,"target":"_blank"}},[_vm._v("Contact us")]),_vm._v(" if you would like to purchase the following widgets ")]),_c('b-row',_vm._l((_vm.getWidgets(false)),function(widget,index){return _c('b-col',{key:index,staticClass:"pb-4 mb-1",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('b-card',{attrs:{"tag":"article"}},[_c('b-row',{staticClass:"no-gutters widgetTitleRow"},[_c('span',{staticClass:"widget-icon",style:({
                'background-color': widget.iconColor,
                'border-radius': '5px 0px',
              })},[_c('WidgetIcons',{attrs:{"iconName":widget.widgetCategoryName}})],1),_c('p',{staticClass:"otherWidgetTitle"},[_c('strong',[_vm._v(_vm._s(widget.widgetCategoryName))])])]),_c('b-card-text',[_vm._v(_vm._s(widget.description)+" ")])],1)],1)}),1)],1):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticClass:"page-sub-header py-1"},[_c('strong',[_vm._v("Available Widgets")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticClass:"page-sub-header pt-5"},[_c('strong',[_vm._v("Other Widgets")])])
}]

export { render, staticRenderFns }